import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { GroupCellRendererParams, ICellRendererComp, ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { NgClass, NgStyle } from '@angular/common';
import { Component, effect, Signal } from '@angular/core';

interface GroupRowParams extends GroupCellRendererParams {
  flagCodes: Record<string, string>;
  rowData: Signal<any[]>;
  groupColors: Signal<any>;
  allColors: Signal<string[]>;
}

@Component({
  standalone: true,
  template: ` <div class="row">
    @if (node.group) {
      <span class="groupTitle">key: {{ node?.key }}</span>
      <span class="medal gold" attr.aria-label="{{ node?.key }}"><i class="fas fa-medal"></i>{{ node?.allChildrenCount }}</span>
    } @else {
      {{ params.getValue() }}
    }
  </div>`,
  styles: [
    `
      .row {
        display: inline-block;
      }

      .groupTitle {
        font-size: 16px;
        font-weight: bold;
      }

      .medal {
        margin: 0 5px;
      }

      .gold {
        color: #e4ab11;
      }

      .silver {
        color: #bbb4bb;
      }

      .bronze {
        color: #be9088;
      }

      .flag {
        margin: 0 4px;
        position: relative;
        top: 2px;
      }
    `
  ]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GroupRowInnerRenderer implements ICellRendererAngularComp {
  public node!: IRowNode;
  public params!: GroupRowParams;
  rowData: Signal<any[]>;

  agInit(params: GroupRowParams): void {
    this.params = params;
    this.node = params.node;
    console.log('params : ', params);
    this.rowData = params.rowData;
  }

  refresh(): boolean {
    return false;
  }
}

@Component({
  standalone: true,
  template: `
    <span
      [ngClass]="{
        'custom-group-label': node?.group
      }"
      [ngStyle]="{
        backgroundColor: color
      }"
      >{{ value }}</span
    >
  `,
  imports: [NgClass, NgStyle],
  styles: [
    `
      .custom-group-label {
        background: var(--ag-chip-background-color);
        border-radius: calc(var(--ag-grid-size) * 4);
        padding: 4px calc(var(--ag-grid-size)* 1.5);
        border: 1px solid var(--ag-chip-border-color);
      }
    `
  ]
})
export class SimpleCellRendererComponent implements ICellRendererAngularComp {
  // eGui: any;
  public node!: IRowNode;
  public color!: string;
  public params!: GroupRowParams;
  public value!: any;
  rowData: Signal<any[]>;
  groupColors: Signal<any>;
  allColors: Signal<string[]>;

  constructor() {
    effect(() => {
      const rowData = this.rowData();
      const groupColors = this.groupColors();
      const allColors = this.allColors();
      console.log('GroupRowInnerRenderer : rowData ', rowData);
      console.log('GroupRowInnerRenderer : groupColors ', groupColors);
      console.log('GroupRowInnerRenderer : allColors ', allColors);
    });
  }

  agInit(params: GroupRowParams): void {
    this.params = params;
    this.node = params.node;
    console.log('agInit params : ', params);
    this.rowData = params.rowData;
    this.groupColors = params.groupColors;
    this.allColors = params.allColors;

    this.value = params.value;
    if (!params.value?.length) {
      this.value = params.node?.group ? `No ${params.node?.field}s` : 'test';
    }

    if (this.params?.node?.group) {
      const groupColors = this.groupColors();
      const allColors = this.allColors();
      /*
      const rowGroupPanel = document.querySelector('.ag-column-drop');
      // aria-posinset
      const dropCells = rowGroupPanel.querySelectorAll('.ag-column-drop-cell');
      dropCells.forEach((cell: any) => {
        console.log('rowGroupIndex ', this.node.rowGroupIndex);
        console.log('rowGroupIndex ', this.node.rowGroupColumn.getColId());
        console.log('posinset ', cell.getAttribute('aria-posinset'));
        const posinset = cell.getAttribute('aria-posinset');
        // const field = cell.innerText.toLowerCase();
        const field = this.node.rowGroupColumn.getColId();
        if (this.node.rowGroupIndex + 1 === Number(posinset)) {
          if (groupColors[field]) {
            cell.style.backgroundColor = groupColors[field];
          }
        }
      });
      */

      // this.color = groupColors[this.params.node.field];
      this.color = allColors[this.params.node.rowGroupIndex];
    }
    /*
    console.log('params : ', params);
    const tempDiv = document.createElement('div');

    let value = params.value;
    if (!params.value?.length) {
      value = params.node?.group ? `No ${params.node?.field}s` : 'test';
    }
    tempDiv.innerHTML = `<span style="background-color: ${color}; padding: 2px; ">${value}</span>`;
    this.eGui = tempDiv.firstChild!;
     */
  }

  refresh(): boolean {
    return false;
  }
}

export class SimpleCellRenderer1 implements ICellRendererComp {
  eGui: any;

  init(params: ICellRendererParams) {
    console.log('params : ', params);
    const tempDiv = document.createElement('div');
    const color = params.node.group ? '#CC222244' : '#33CC3344';
    let value = params.value;
    if (!params.value?.length) {
      value = params.node?.group ? `No ${params.node?.field}s` : 'test';
    }
    tempDiv.innerHTML = `<span style="background-color: ${color}; padding: 2px; ">${value}</span>`;
    this.eGui = tempDiv.firstChild!;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

// Function to generate color shades

/* eslint-disable */



function generateColorShades(startColor, endColor, steps) {
  // Remove '#' if present and convert to RGB
  const start = startColor.replace('#', '');
  const end = endColor.replace('#', '');

  // Convert hex to RGB
  const startRGB = {
    r: parseInt(start.substring(0, 2), 16),
    g: parseInt(start.substring(2, 4), 16),
    b: parseInt(start.substring(4, 6), 16)
  };

  const endRGB = {
    r: parseInt(end.substring(0, 2), 16),
    g: parseInt(end.substring(2, 4), 16),
    b: parseInt(end.substring(4, 6), 16)
  };

  // Calculate the step size for each color component
  const stepR = (endRGB.r - startRGB.r) / (steps - 1);
  const stepG = (endRGB.g - startRGB.g) / (steps - 1);
  const stepB = (endRGB.b - startRGB.b) / (steps - 1);

  // Generate array of colors
  const colorShades = [];

  for (let i = 0; i < steps; i++) {
    const r = Math.round(startRGB.r + (stepR * i));
    const g = Math.round(startRGB.g + (stepG * i));
    const b = Math.round(startRGB.b + (stepB * i));

    // Convert RGB back to hex
    const hex = '#' +
      (r < 16 ? '0' : '') + r.toString(16) +
      (g < 16 ? '0' : '') + g.toString(16) +
      (b < 16 ? '0' : '') + b.toString(16);

    colorShades.push(hex);
  }

  return colorShades;
}


/************************/

// Function to calculate the brightness of a color using the luminance formula
export const calculateBrightness = (rgb) => {
  const [r, g, b] = rgb.rgb;
  // Using the luminance formula to calculate brightness
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

// Function to sort the colors based on brightness
export const sortColorsByBrightness = (colors, shade = true) => {
  return colors.sort((a, b) => calculateBrightness(shade ? a.shade : a.tint) - calculateBrightness(shade ? b.shade : b.tint));
};

const calculateTintAndShade = (
  hexColor, // using #663399 as an example
  percentage = 0.1 // using 10% as an example
) => {
  // const r = parseInt(hexColor.slice(1, 3), 16);
  // const g = parseInt(hexColor.slice(3, 5), 16);
  // const b = parseInt(hexColor.slice(5, 7), 16);

  const start = hexColor.replace('#', '');

  const r = parseInt(start.substring(0, 2), 16);
    const g = parseInt(start.substring(2, 4), 16);
    const b = parseInt(start.substring(4, 6), 16);

  /*
     From this part, we are using our two formulas
     in this case, here is the formula for tint,
     please be aware that we are performing two validations
     we are using Math.min to set the max level of tint to 255,
     so we don't get values like 280 ;)
     also, we have the Math.round so we don't have values like 243.2
     both validations apply for both tint and shade as you can see */
  const tintR = Math.round(Math.min(255, r + (255 - r) * percentage)); // 117
  const tintG = Math.round(Math.min(255, g + (255 - g) * percentage)); // 71
  const tintB = Math.round(Math.min(255, b + (255 - b) * percentage)); // 163

  const shadeR = Math.round(Math.max(0, r - r * percentage)); // 92
  const shadeG = Math.round(Math.max(0, g - g * percentage)); // 46
  const shadeB = Math.round(Math.max(0, b - b * percentage)); // 138

  /*
     Now with all the values calculated, the only missing stuff is
     getting our color back to hexadecimal, to achieve that, we are going
     to perform a toString(16) on each value, so we get the hex value
     for each color, and then we just append each value together and voilà!*/
  return {
    tint: {
      r: tintR,
      g: tintG,
      b: tintB,
      rgb: [tintR, tintG, tintB],
      hex: '#' + [tintR, tintG, tintB].map((x) => x.toString(16).padStart(2, '0')).join('') // #7547a3
    },
    shade: {
      r: shadeR,
      g: shadeG,
      b: shadeB,
      rgb: [shadeR, shadeG, shadeB],
      hex: '#' + [shadeR, shadeG, shadeB].map((x) => x.toString(16).padStart(2, '0')).join('') // #5c2e8a
    }
  };
};

export const generateColorShadesWithOpacity = (color: string, numShades: number) => {

  const startColor = '#4f79b7';  // '#FF0000'; // Red
  const endColor = '#e9eff6'; //'#0000FF';   // Blue

  const shades1 = generateColorShades(startColor, endColor, numShades);
  shades1.forEach((c1) => {
    printF('generateColorShades c1 : ', c1);
  })
  console.log(shades1);

  const shades = [];
  const type: any = 'tint'; // shade || tint
  printF('calculateTintAndShade color : ', color);

  for (let i = numShades - 1; i >= 0; i -= 1) {
    const rrr = calculateTintAndShade(color, 0.3);
    shades.push(rrr);
    color = rrr[type].hex;
  }
  const sorted = sortColorsByBrightness(shades, type === 'shade');
  sorted.forEach((clr) => {
    // printF("calculateTintAndShade shade : ", clr.shade.hex)
    printF('calculateTintAndShade tint : ', clr.tint.hex);
  });
  console.log('sorted : ', sorted);
  return sorted.map((clr) => clr[type].hex);
};

const printF = (msg, v) => {
  console.log(`%c ${msg} `, `background: ${v};`);
};

import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  DeviceModbusRequestTable,
  DeviceModbusRequestTableState,
  ModbusListName,
  ModbusTable,
  ModbusTablePropertyState
} from '@iot-platform/models/dalia';

import { get } from 'lodash';
import { AbstractMapper, Field, FieldMappingModelOptions, FieldType } from './abstract-mapper';
import { DeviceModbusRequestTableHelpers } from './device-modbus-request-table.helpers';

export class DeviceModbusRequestTableMapper extends AbstractMapper<DeviceModbusRequestTable, DeviceModbusRequestTable, DeviceModbusRequestTableState> {
  readonly ATTRIBUTE_MAPPER = {
    channel: 'Channel',
    modbusSlaveAddress: 'Slave_address',
    modbusDataAddress: 'Data_address',
    modbusFunction: 'Code_function',
    modbusType: 'Modbus_type',
    warmupTime: 'Warm_up_time',
    baudrate: 'Baudrate',
    parity: 'Parity',
    stopBits: 'Stop_bits',
    modbusDataValue: 'Data_value',
    requestExecutionStatus: 'Request_execution_status',
    timeStamp: 'Timestamp',
    resultCode: 'Result_code'
  };

  readonly STATE_DATA_MAPPING = {
    modbusParameters: {
      modbusSlaveAddress: true,
      modbusDataAddress: true,
      modbusFunction: true,
      modbusType: true,
      modbusDataValue: true,
      baudrate: true,
      parity: true,
      stopBits: true
    },
    powerParameters: {
      channel: true,
      warmupTime: true
    },
    requestResult: {
      requestExecutionStatus: true,
      timeStamp: true,
      resultCode: true
    }
  };

  readonly DATA_MAPPING = {
    channel: true,
    modbusSlaveAddress: true,
    modbusDataAddress: true,
    modbusFunction: true,
    modbusType: true,
    warmupTime: true,
    baudrate: true,
    parity: true,
    stopBits: true,
    modbusDataValue: true,
    requestExecutionStatus: true,
    timeStamp: true,
    resultCode: true
  };

  readonly FIELDS: Field[] = [
    {
      type: FieldType.COMPLEX,
      name: 'modbusParameters',
      children: [
        {
          name: 'modbusSlaveAddress',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusSlaveAddress
        },
        {
          name: 'modbusDataAddress',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusDataAddress
        },
        {
          name: 'modbusFunction',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusFunction,
          modbusList: ModbusListName.LstFctMB,
          mapped: true
        },
        {
          name: 'modbusType',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusType,
          modbusList: ModbusListName.LstMbType,
          mapped: true
        },
        {
          name: 'modbusDataValue',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.modbusDataValue
        },
        {
          name: 'baudrate',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.baudrate,
          suppressPropertyStateTransform: true,
          transformValue: ({ data, configuration, valueGetter }) => {
            const key = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableIndexKey(data);
            return DeviceModbusRequestTableHelpers.getBaudrateByValue(key, configuration, valueGetter);
          }
        },
        {
          name: 'parity',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.parity,
          modbusList: ModbusListName.LstParity,
          mapped: true
        },
        {
          name: 'stopBits',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.stopBits,
          modbusList: ModbusListName.LstMbStopbits,
          mapped: true
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'powerParameters',
      children: [
        {
          name: 'channel',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.channel,
          modbusList: ModbusListName.LstChannelSensor,
          mapped: true
        },
        {
          name: 'warmupTime',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.warmupTime
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'requestResult',
      children: [
        {
          name: 'requestExecutionStatus',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.requestExecutionStatus,
          modbusList: ModbusListName.LstMbReqStatus,
          mapped: true
        },
        {
          name: 'timeStamp',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.timeStamp
        },
        {
          name: 'resultCode',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.resultCode,
          modbusList: ModbusListName.LstModbusError,
          mapped: true
        }
      ]
    }
  ];

  get fields(): Field[] {
    return this.FIELDS;
  }

  get stateDataMapping() {
    return this.STATE_DATA_MAPPING;
  }

  get dataMapping() {
    return this.DATA_MAPPING;
  }

  getPropertyState = (field: Field, data: DeviceModbusRequestTable, modbusTable: ModbusTable): ModbusTablePropertyState => {
    const key = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableIndexKey(data);
    let status = DeviceConfigurationStatus.CURRENT;
    const attrPath = `${key}.${field.path}`;
    let newCollectionName = 'pending';
    if (data?.status === DeviceConfigurationStatus.PUBLISHED) {
      newCollectionName = 'target';
    }

    const valueGetter = (k: string) => (data?.isCreated ? [newCollectionName, k] : ['current', k, 'v']);

    const configuration = get(data, ['device', 'configuration'], {}) as DeviceConfiguration;

    let oldValue = get(configuration, valueGetter(attrPath), null);
    const newValue = get(configuration, [newCollectionName, attrPath], null);

    if (field.transformValue && !field?.suppressPropertyStateTransform) {
      oldValue = field.transformValue({
        field,
        modbusTable,
        data,
        value: oldValue,
        configuration,
        valueGetter
      });
    }

    if (newValue !== null && (data?.isCreated || oldValue !== newValue)) {
      status = data.status;
    }

    const displayValue = this.getPropertyStateDisplayOldValue(field, modbusTable, data, oldValue, configuration, valueGetter, key);

    return {
      oldValue,
      newValue,
      displayValue,
      status,
      attrName: field.name,
      parentAttrName: field?.parentField?.name,
      key: attrPath
    };
  };

  getMappingModel(options: Partial<FieldMappingModelOptions<DeviceModbusRequestTable>>): DeviceModbusRequestTable {
    const pathPrefix = DeviceModbusRequestTableHelpers.getDeviceModbusRequestTableIndexKey(options.data);
    const status = get(options, ['data', 'status'], DeviceConfigurationStatus.CURRENT);
    return super.applyMapping({ ...options, pathPrefix, status, mergeConfig: true });
  }
}

import {
  DeviceConfiguration,
  DeviceConfigurationStatus,
  DeviceTank,
  DeviceTankState,
  ModbusListName,
  ModbusTable,
  ModbusTablePropertyState
} from '@iot-platform/models/dalia';

import { get } from 'lodash';
import { AbstractMapper, Field, FieldMappingModelOptions, FieldType } from './abstract-mapper';
import { DeviceTankHelpers } from './device-tank.helpers';
import { DeviceVariableHelpers } from './device-variable.helpers';

export class DeviceTankMapper extends AbstractMapper<DeviceTank, DeviceTank, DeviceTankState> {
  readonly DEFAULT_BAR_GRAPH_DISPLAY_VALUE = 'IOT_DICTIONARY.DEFAULT';

  readonly ATTRIBUTE_MAPPER = {
    type: 'Type',
    gasType: 'Gas_type',
    serialNumber: 'Serial_number',

    maxLiquidHeight: 'Max_liquid_height',
    headHeight: 'Head_height',
    innerDiameter: 'Inner_diameter',
    maximumVolume: 'Maximum_volume',
    referencePressure: 'Reference_pressure',
    referencePressureUnit: 'Reference_pressure_unit',
    cylinderLength: 'Cylinder_length',

    variableDp: 'Index_variable_DP',
    variableDpIndex: 'Index_variable_DP',
    variableDpUnit: 'Unit_displayed_for_DP',
    variableDpDecimal: 'decimal_for_DP_display',

    variableP: 'Index_variable_P',
    variablePIndex: 'Index_variable_P',
    variablePUnit: 'Unit_displayed_for_P',
    variablePDecimal: 'decimal_for_P_display',

    indexedBarGraphDisplay: 'indexedBarGraphDisplay', // Index of the variable
    variableBarGraphDisplay: 'indexedBarGraphDisplay' // computed variable
  };

  readonly STATE_DATA_MAPPING = {
    generalInformation: {
      type: true,
      gasType: true,
      serialNumber: true
    },
    physicalCharacteristics: {
      maxLiquidHeight: true,
      headHeight: true,
      innerDiameter: true,
      maximumVolume: true,
      referencePressure: true,
      referencePressureUnit: true,
      cylinderLength: true
    },
    variables: {
      variableDp: true,
      variableDpIndex: false,
      variableDpUnit: true,
      variableDpDecimal: true,
      variableP: true,
      variablePIndex: false,
      variablePUnit: true,
      variablePDecimal: true,
      variableBarGraphDisplay: true,
      indexedBarGraphDisplay: true
    }
  };

  readonly DATA_MAPPING = {
    type: true,
    gasType: true,
    serialNumber: true,
    maxLiquidHeight: true,
    headHeight: true,
    innerDiameter: true,
    maximumVolume: true,
    referencePressure: true,
    referencePressureUnit: true,
    cylinderLength: true,
    variableDp: true,
    variableDpIndex: true,
    variableDpUnit: true,
    variableDpDecimal: true,
    variableP: true,
    variablePIndex: true,
    variablePUnit: true,
    variablePDecimal: true,
    variableBarGraphDisplay: true,
    indexedBarGraphDisplay: true
  };

  readonly FIELDS: Field[] = [
    {
      type: FieldType.COMPLEX,
      name: 'generalInformation',
      children: [
        {
          name: 'type',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.type,
          modbusList: ModbusListName.LstTankType,
          mapped: true
        },
        {
          name: 'gasType',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.gasType,
          modbusList: ModbusListName.LstGasType,
          mapped: true
        },
        {
          name: 'serialNumber',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.serialNumber
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'physicalCharacteristics',
      children: [
        {
          name: 'maxLiquidHeight',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.maxLiquidHeight
        },
        {
          name: 'headHeight',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.headHeight
        },
        {
          name: 'innerDiameter',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.innerDiameter
        },
        {
          name: 'maximumVolume',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.maximumVolume
        },
        {
          name: 'referencePressure',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.referencePressure
        },
        {
          name: 'referencePressureUnit',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.referencePressureUnit,
          modbusList: ModbusListName.LstUnitEnum,
          mapped: true
        },
        {
          name: 'cylinderLength',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.cylinderLength
        }
      ]
    },
    {
      type: FieldType.COMPLEX,
      name: 'variables',
      children: [
        {
          name: 'variableDp',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variableDp,
          computed: true,
          computedVariable: true
        },
        {
          name: 'variableDpIndex',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variableDpIndex
        },
        {
          name: 'variableDpUnit',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variableDpUnit,
          modbusList: ModbusListName.LstUnitEnum,
          mapped: true
        },
        {
          name: 'variableDpDecimal',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variableDpDecimal
        },
        {
          name: 'variableP',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variableP,
          computed: true,
          computedVariable: true,
          optional: true
        },
        {
          name: 'variablePIndex',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variablePIndex
        },
        {
          name: 'variablePUnit',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variablePUnit,
          modbusList: ModbusListName.LstUnitEnum,
          mapped: true
        },
        {
          name: 'variablePDecimal',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variablePDecimal
        },
        {
          name: 'variableBarGraphDisplay',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.variableBarGraphDisplay,
          computed: true,
          computedVariable: true,
          optional: true,
          suppressPropertyStateTransform: true,
          suppressPropertyCompute: true, // The transformValue callback do the computation
          transformValue: ({ value, field, data: tank, configuration, valueGetter }) => {
            if (value === 0) {
              return this.DEFAULT_BAR_GRAPH_DISPLAY_VALUE;
            }
            const key = DeviceTankHelpers.getTankIndexKey(tank);
            return this.getComputedProperty(field, value, configuration, valueGetter, key);
          }
        },
        {
          name: 'indexedBarGraphDisplay',
          type: FieldType.BASIC,
          path: this.ATTRIBUTE_MAPPER.indexedBarGraphDisplay
        }
      ]
    }
  ];

  get fields(): Field[] {
    return this.FIELDS;
  }

  get stateDataMapping() {
    return this.STATE_DATA_MAPPING;
  }

  get dataMapping() {
    return this.DATA_MAPPING;
  }

  getPropertyState = (field: Field, data: DeviceTank, modbusTable: ModbusTable): ModbusTablePropertyState => {
    const key = DeviceTankHelpers.getTankIndexKey(data);
    let status = DeviceConfigurationStatus.CURRENT;
    const attrPath = `${key}.${field.path}`;
    let newCollectionName = 'pending';
    if (data?.status === DeviceConfigurationStatus.PUBLISHED) {
      newCollectionName = 'target';
    }

    const valueGetter = (k: string) => (data?.isCreated ? [newCollectionName, k] : ['current', k, 'v']);

    const configuration = get(data, ['device', 'configuration'], {}) as DeviceConfiguration;

    let oldValue = get(configuration, valueGetter(attrPath), null);
    const newValue = get(configuration, [newCollectionName, attrPath], null);

    if (field.transformValue && !field?.suppressPropertyStateTransform) {
      oldValue = field.transformValue({
        field,
        modbusTable,
        data,
        value: oldValue,
        configuration,
        valueGetter
      });
    }

    if (field.computed) {
      // In case computed values are impacted by removed variables
      // We change their status to DELETED
      // oldValue === Old index
      // newValue === new index

      const currentIndexes = DeviceVariableHelpers.getAllVariableNames(configuration, true).map((item) => item.index);

      if (oldValue === 0 && newValue === null) {
        oldValue = null;
      }

      const noOldValueAndHasNewValue = oldValue === null && newValue !== null;
      const noNewValueAndHasOldValue = newValue === null && oldValue !== null;
      const hasBoth = oldValue !== null && newValue !== null;

      const noOldIndexAndNewIndexRemoved = noOldValueAndHasNewValue && !currentIndexes.includes(newValue);
      const hasBothAndNewIndexRemoved = hasBoth && !currentIndexes.includes(newValue);
      const noNewIndexAndOldIndexRemoved = noNewValueAndHasOldValue && !currentIndexes.includes(oldValue);

      // Optional variables could be unused
      // In this case variable index will be 0
      const isVariablePUnused = field.optional && ((oldValue === 0 && newValue === null) || newValue === 0);

      // Unused variables should not be considered as removed
      if (!isVariablePUnused && (noOldIndexAndNewIndexRemoved || noNewIndexAndOldIndexRemoved || hasBothAndNewIndexRemoved)) {
        status = DeviceConfigurationStatus.DELETED;
      }

      if (!field?.suppressPropertyStateCompute) {
        // Set old computed value
        oldValue = this.getComputedProperty(field, oldValue, configuration, valueGetter, key);
      }
    }

    if (status !== DeviceConfigurationStatus.DELETED && newValue !== null && (data?.isCreated || oldValue !== newValue)) {
      status = data.status;
    }

    const displayValue = this.getPropertyStateDisplayOldValue(field, modbusTable, data, oldValue, configuration, valueGetter, key);

    return {
      oldValue,
      newValue,
      displayValue,
      status,
      attrName: field.name,
      parentAttrName: field?.parentField?.name,
      key: attrPath
    };
  };

  getMappingModel(options: Partial<FieldMappingModelOptions<DeviceTank>>): DeviceTank {
    const pathPrefix = DeviceTankHelpers.getTankIndexKey(options.data);
    const status = get(options, ['data', 'status'], DeviceConfigurationStatus.CURRENT);
    return super.applyMapping({ ...options, pathPrefix, status, mergeConfig: true });
  }
}

import { AgGridAngular } from '@ag-grid-community/angular';
import {
  CellPosition,
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  GetLocaleTextParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  HeaderPosition,
  NavigateToNextCellParams,
  NavigateToNextHeaderParams,
  RowClickedEvent,
  RowNode,
  RowSelectedEvent,
  SelectionChangedEvent,
  SideBarDef,
  SortChangedEvent,
  TabToNextCellParams,
  TabToNextHeaderParams,
  ValueGetterParams
} from '@ag-grid-community/core';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  Inject,
  Injector,
  model,
  OnInit,
  output,
  Renderer2,
  signal,
  untracked,
  ViewChild,
  ViewContainerRef,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ConditionProcessorUtil } from '@iot-platform/iot-platform-utils';
import { Filter, Pagination } from '@iot-platform/models/common';
import {
  ExportParams,
  GridNavigationType,
  HeaderType,
  I4BButtonColumn,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  I4BGridSort,
  I4BSelectionColumn
} from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { debounce, get } from 'lodash';
import { debounceTime } from 'rxjs';
import { GridExportDialogComponent } from '../../components/grid-export/grid-export-dialog/grid-export-dialog.component';
import { PaginatorComponent } from '../../components/pagination/paginator/paginator.component';

import {
  CELL_CONCEPTS_WITH_IS_DISPLAY_OPTION,
  FRAMEWORK_COMPONENTS,
  GRID_GROUP_HEADER_HEIGHT,
  GRID_HEADER_HEIGHT,
  GRID_ROW_HEIGHT
} from '../../constants/grid-settings.constants';
import { AgGridNavigationHelpers } from '../../helpers/ag-grid-navigation.helpers';
import { GridFiltersHelpers } from '../../helpers/grid-filters.helpers';
import { GridExportService } from '../../services/grid-export.service';

import { GridsDbActions } from '../../state/actions';
import { SimpleCellRendererComponent } from './group-row-inner-renderer.component';
import { generateColorShadesWithOpacity } from './random-colors';

const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

@Component({
  standalone: true,
  selector: 'grid-engine-grid-page',
  templateUrl: './grid-page.component.html',
  imports: [AgGridAngular, PaginatorComponent],
  styleUrls: ['./grid-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridPageComponent implements OnInit, AfterViewInit {
  grid = model<I4BGrid<I4BGridOptions, I4BGridData>>();
  gridSort = model<I4BGridSort[]>([]);
  currentFilters = model<Filter[]>([]);
  userPermissions = model<any>();
  visibleNodeId = model<string>();
  hidePaginator = model<boolean>(false);
  disableAutoFit = model<boolean>(false);

  groupColors = signal({});
  allColors = signal([]);
  dispatchMasterViewEngineEvent = output<any>();
  dispatchGridEvent = output<any>();
  pageChange = output<Pagination>();
  sortChange = output<{
    event: SortChangedEvent;
    grid: I4BGrid<I4BGridOptions, I4BGridData>;
  }>();
  selectRow = output<RowClickedEvent>();

  filters: WritableSignal<Filter[]> = signal([]);
  mvName: WritableSignal<string> = signal('');
  columnDefs: WritableSignal<ColDef[]> = signal([]);
  rowData: WritableSignal<any[]> = signal([]);
  rowClassRules: WritableSignal<any> = signal({});

  gridOptions: GridOptions = {
    suppressTouch: true,
    enableRangeSelection: true,
    copyHeadersToClipboard: false,
    suppressCopyRowsToClipboard: true,
    suppressMenuHide: true,
    headerHeight: GRID_HEADER_HEIGHT,
    rowHeight: GRID_ROW_HEIGHT,
    groupHeaderHeight: GRID_GROUP_HEADER_HEIGHT,
    components: FRAMEWORK_COMPONENTS,
    rowSelection: 'multiple',
    rowBuffer: 20,
    debounceVerticalScrollbar: true,
    animateRows: false,
    enableCellTextSelection: true,
    suppressRowDeselection: true,
    suppressRowClickSelection: true,
    pagination: false,

    // ****** 1
    /*
    groupDisplayType: 'groupRows', // singleColumn, groupRows, multipleColumns, custom
    groupRowRendererParams: {
      innerRenderer: GroupRowInnerRenderer,
      suppressCount: true,
      rowData: this.rowData
    },
    rowGroupPanelShow: 'always',
    groupDefaultExpanded: -1, // -1 opened, 1 closed
    groupAllowUnbalanced: true,
*/

    // ****** 2
    /*

    */
    autoGroupColumnDef: {
      headerName: 'My Group',
      // field: 'entity.name',
      // minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      // provide extra params to the cellRenderer
      cellRendererParams: {
        // innerRenderer: SimpleCellRenderer1,
        innerRenderer: SimpleCellRendererComponent,
        rowData: this.rowData,
        groupColors: this.groupColors,
        allColors: this.allColors,
        autoSize: true
      },
      /*
      toolPanelClass: (params) => {
        console.log('toolPanelClass params : ', params);
        return 'tp-bronze';
      },
      cellClass: (params) => {
        console.log('cellClass params : ', params);
        return 'tp-bronze';
      },
      cellClassRules: {
        'tp-bronze': (params) => {
          console.log('cellClassRules : ', params);
          return true;
        }
      }*/
    },
    // groupDefaultExpanded: -1,
    groupDisplayType: 'singleColumn', // singleColumn, groupRows, multipleColumns, custom
    // groupDisplayType: 'singleColumn',
    // groupDisplayType:'groupRows'
    // [groupDisplayType]="'multipleColumns'"
    // rowGroupPanelShow: 'always',
    // groupDefaultExpanded: 1,
    // groupAllowUnbalanced: true,
    // groupDefaultExpanded: -1, // -1 opened, 1 closed
    rowGroupPanelShow: 'onlyWhenGrouping',// "always" | "onlyWhenGrouping" | "never" = "always";
    groupAllowUnbalanced: false,
    groupDefaultExpanded: -1, // -1 opened, 1 closed
    // groupHideOpenParents: true,
    // cellRenderer: 'agGroupCellRenderer',

    // ****3
    /*
    groupDisplayType: 'custom',
    rowGroupPanelShow: 'always',
    groupAllowUnbalanced: true,
*/
    onRowDoubleClicked: (event) => {
      this.dispatchMasterViewEngineEvent.emit({
        type: 'open',
        options: { selected: event.context },
        rawData: event.data
      });
    },
    onRowClicked: (event) => {
      this.dispatchGridEvent.emit({
        event
      });
    },
    onColumnMoved: (event: ColumnMovedEvent) => {
      this.dispatchGridEvent.emit({
        event
      });
    },
    onColumnResized: (event: ColumnResizedEvent) => {
      this.dispatchGridEvent.emit({
        event
      });
    },
    getRowId: (params) => params.data.id,
    onModelUpdated: debounce(() => {
      this.autoSizeAll();
      this.sizeColumnsToFit();
      this.setRowGroupColors();
    }, 300)
    /*
    getRowStyle: (params) => {
      if (params.node.group) {
        const field = params.node.field;
        // Get the group field
        return {
          backgroundColor: this.groupColors[field] || 'inherit' // Use the matching color or default
        };
      }
      return null;
    }*/
  };
  defaultColDef = {
    // suppressHeaderMenuButton: true,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: false,
    minWidth: 80,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true
  };
  rowSelected: EventEmitter<RowSelectedEvent> = new EventEmitter<RowSelectedEvent>();
  sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        toolPanelParams: {
          suppressExpandAll: true,
          suppressFilterSearch: true
        }
      }
    ],
    hiddenByDefault: true,
    position: 'right',
    defaultToolPanel: 'columns'
  };

  @ViewChild('exportViewRef', { read: ViewContainerRef }) protected readonly exportViewRef: ViewContainerRef;
  protected gridApi: GridApi;
  protected singleActionsEnabled: WritableSignal<boolean> = signal(true);
  protected readonly renderer: Renderer2 = inject(Renderer2);
  protected readonly translateService: TranslateService = inject(TranslateService);
  @Inject(DOCUMENT) protected readonly document: Document = inject(DOCUMENT);
  protected readonly gridExportService: GridExportService = inject(GridExportService);
  protected readonly dialog: MatDialog = inject(MatDialog);
  protected readonly store: Store = inject(Store);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly injector: Injector = inject(Injector);

  constructor() {
    effect(
      () => {
        this.userPermissions();
        const grid = this.grid();
        untracked(() => {
          if (grid) {
            grid.export = this.exportData.bind(this);
            this.rowClassRules.set(get(grid, ['gridOptions', 'rowClassRules']));
            this.rowData.set([...get(grid, ['data', 'response', 'data'], [])]);
            this.gridSort.set(get(grid, ['gridOptions', 'gridSort'], []));
            if (grid?.columns) {
              this.initColumnDefs(grid);
            }
          }
        });
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit() {
    effect(
      () => {
        const rowData = this.rowData();
        if (this.gridApi) {
          this.gridApi.updateGridOptions({ rowData });
          this.gridApi.refreshCells({ force: true });
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
    effect(
      () => {
        const gridSort = this.gridSort();
        this.setSort(gridSort);
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  autoSizeAll(skipHeader = false) {
    const columnIds: string[] = ['ag-Grid-AutoColumn'];
    this.gridApi?.getColumns?.()?.forEach((column) => {
      if (column.getColDef()?.cellRendererParams?.autoSize) {
        columnIds.push(column.getId());
      }
    });
    if (columnIds.length) {
      this.gridApi?.autoSizeColumns?.(columnIds, skipHeader);
    }
  }

  getLocaleText = (params: GetLocaleTextParams) => this.translateService.instant(`AG_GRID.${params.key}`);

  ngAfterViewInit(): void {
    this.gridExportService.setViewRef(this.exportViewRef);
    this.rowSelected.pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.gridApi.refreshHeader();
    });
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.setFlexColumnClass();
  }

  setRowGroupColors() {
    if (this.gridApi) {
      const groupColors = this.groupColors();
      const allColors = this.allColors();
      const rowGroupPanel = document.querySelector('.ag-column-drop');
      const dropCells = rowGroupPanel.querySelectorAll('.ag-column-drop-cell');
      const rowDropZoneParams = this.gridApi.getRowDropZoneParams();
      console.log('rowDropZoneParams : ', rowDropZoneParams);
      this.gridApi.getRowGroupColumns().forEach((rowGroupCol, index) => {
        console.log('rowGroupCol :', rowGroupCol);
        dropCells.forEach((cell: any) => {
          const posinset = cell.getAttribute('aria-posinset');
          // const field = cell.innerText.toLowerCase();
          // const field = node.rowGroupColumn.getColId();
          const field = rowGroupCol.getColId();
          if (index + 1 === Number(posinset)) {
            cell.style.backgroundColor = allColors[index];
            if (groupColors[field]) {
              // cell.style.backgroundColor = groupColors[field];
            }
          }
        });
      });
      /*
      this.gridApi.forEachNode((node) => {
        if (node.group) {

        }
      });
      */
    }
  }

  onFirstDataRendered(): void {
    const gridSort = this.gridSort();
    const visibleNodeId = this.visibleNodeId();
    this.setSort(gridSort);
    if (visibleNodeId) {
      this.scrollTo(this.gridApi.getRowNode(visibleNodeId)?.rowIndex);
    }
  }

  onPageChange(pagination: Pagination): void {
    this.pageChange.emit(pagination);
    this.scrollTo(0);
  }

  scrollTo(index: number, position: 'top' | 'bottom' | 'middle' | null = 'top'): void {
    this.gridApi.ensureIndexVisible(index || 0, position);
  }

  onGridColumnsChanged() {
    if (this.disableAutoFit() === false) {
      this.sizeColumnsToFit();
    }
    this.initHeaderSize();
  }

  sizeColumnsToFit(): void {
    const timeout = setTimeout(() => {
      const viewport: HTMLElement = this.document.querySelector('[ref="centerContainer"].ag-center-cols-viewport');
      const container: HTMLElement = this.document.querySelector('[ref="eContainer"].ag-center-cols-container');
      if (viewport && container && container.offsetWidth > 0 && container.offsetWidth - 1 < viewport.offsetWidth) {
        this.gridApi?.sizeColumnsToFit?.();
      }
      clearTimeout(timeout);
    }, 0);
  }

  onSortChange(event: SortChangedEvent) {
    this.sortChange.emit({ event, grid: this.grid() });
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    this.dispatchMasterViewEngineEvent.emit({
      type: 'selectionChanged',
      rawData: event.api.getSelectedNodes()
    });
    this.singleActionsEnabled.set(event.api.getSelectedNodes().length === 0);
  }

  /**
   *
   * Grid navigation
   */
  navigateToNextHeader = (params: NavigateToNextHeaderParams): HeaderPosition | null => {
    const { nextHeader, processedNextHeader } = AgGridNavigationHelpers.processNextHeader(params);
    if (params.key !== GridNavigationType.KEY_DOWN.toString() && params.key !== GridNavigationType.KEY_UP.toString()) {
      return nextHeader;
    }
    if (processedNextHeader.headerRowIndex === -1) {
      this.onNavigateToNextRow(params.api, GridNavigationType.KEY_DOWN, 0);
    }
    return processedNextHeader === nextHeader ? null : processedNextHeader;
  };

  tabToNextHeader = (params: TabToNextHeaderParams): HeaderPosition | null =>
    AgGridNavigationHelpers.moveHeaderFocusUpDown(params.previousHeaderPosition, params.headerRowCount, params.backwards);

  tabToNextCell = (params: TabToNextCellParams): CellPosition | null => {
    const previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;
    return AgGridNavigationHelpers.moveCellFocusUpDown(params.api, GridNavigationType.TAB_TO_NEXT_CELL, previousCell, suggestedNextCell, params.backwards);
  };

  navigateToNextCell = (params: NavigateToNextCellParams): CellPosition | null => {
    const previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;
    const result = AgGridNavigationHelpers.moveCellFocusUpDown(params.api, params.key, previousCell, suggestedNextCell);
    if (result && (params.key === GridNavigationType.KEY_UP.toString() || params.key === GridNavigationType.KEY_DOWN.toString())) {
      this.onNavigateToNextRow(params.api, params.key, result.rowIndex);
    }
    return result;
  };

  onNavigateToNextRow(api: GridApi, type: string, nextRowIndex: number) {
    api.forEachNodeAfterFilterAndSort((node: RowNode) => {
      if (node.rowIndex === nextRowIndex) {
        this.dispatchGridEvent.emit({
          event: {
            type,
            data: node.data
          }
        });
      }
    });
  }

  /**
   *
   * End grid navigation
   */

  private initColumnDefs(grid: I4BGrid<I4BGridOptions, I4BGridData>) {
    let columnDefs = this.getColumnsDefinitions(grid.columns);
    if (grid.gridOptions.buttonColumn?.enabled) {
      const btnColDef = this.getButtonColumnDefinition(grid);
      if (btnColDef) {
        columnDefs = [...columnDefs, btnColDef]; // NEED TO ADD MULTI SELECT COLUMN
      }
    }
    if (grid.gridOptions.selectionColumn?.enabled) {
      const selColDef = this.getSelectionColumnDefinition(grid);
      if (selColDef) {
        columnDefs = [selColDef, ...columnDefs];
      }
    }
    this.columnDefs.set([
      /*
      {
        showRowGroup: true,
        cellRenderer: 'agGroupCellRenderer',
        // provide extra params to the cellRenderer
        cellRendererParams: {
          // turn off the row count
          suppressCount: true,
          // turn off double click for expand
          suppressDoubleClickExpand: true,
          // enable checkbox selection
          checkbox: false,
          // provide an inner renderer
          innerRenderer: GroupRowInnerRenderer,
          // provide an inner renderer
          innerRendererParams: {
            rowData: this.rowData
          }
          // provide a total row value getter
          // totalValueGetter: myTotalValueGetter
        }
      },
      */
      /*
      {
        headerName: 'Type Model',
        minWidth: 200,
        // this tells the grid what values to put into the cell
        showRowGroup: 'type.model',
        // this tells the grid what to use to render the cell
        cellRenderer: 'agGroupCellRenderer',
        // provide an inner renderer
        innerRendererParams: {
          rowData: this.rowData
        }
      } as never,*/
      ...columnDefs
    ]);

    const c1 = '#4f78b7 '; //`#${randomColor()}`; // '#6093dd'; //
    const colors = generateColorShadesWithOpacity(c1, columnDefs.length)
    this.allColors.set([...colors]);
    this.groupColors.update((groupColors) => {
      columnDefs.forEach((col, index) => {
        groupColors[col.field] = colors[index]; // `#${randomColor()}`;
        // groupColors[col.field] = `#${randomColor()}`; // colors[index]; // `#${randomColor()}`;
      });
      console.log('groupColors : ', groupColors);
      return groupColors;
    });
  }

  private setSort(gridSort: I4BGridSort[]) {
    if (this.gridApi && gridSort) {
      // reset the sort state before applying the incoming sortState
      this.gridApi.applyColumnState({
        state: this.gridApi.getColumnState().map((state) => ({ ...state, sort: null, sortIndex: null }))
      });
      this.gridApi.applyColumnState({ state: gridSort as ColumnState[] });
      this.gridApi.refreshHeader();
    }
  }

  private initHeaderSize(): void {
    const headerElement: HTMLElement = this.document.querySelector('.ag-header.ag-focus-managed');
    if (headerElement && headerElement.offsetHeight > 40) {
      const height = GRID_HEADER_HEIGHT + GRID_GROUP_HEADER_HEIGHT + 10;
      this.renderer.setStyle(headerElement, 'height', `${height}px`);
    }
  }

  private exportData(params: ExportParams): void {
    const grid = this.grid();
    const columnDefs = this.columnDefs();
    this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated: false }));
    const {
      gridOptions: { filters, exportParams }
    } = grid;
    this.gridExportService.setGrid(grid);
    this.gridExportService.setParams({
      ...exportParams,
      filters,
      ...params
    });
    this.gridExportService.setColumnsDef([...columnDefs]);
    this.dialog
      .open(GridExportDialogComponent, {
        width: '500px',
        disableClose: true,
        data: { totalElements: params.totalElements }
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated: true }));
      });
  }

  private getColumnsDefinitions(cols: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]): ColDef[] {
    return cols.map((col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => this.getColumn(col));
  }

  private getSelectionColumnDefinition(grid: I4BGrid<I4BGridOptions, I4BGridData>): ColDef | null {
    const userPermissions = this.userPermissions();
    const selectionColumn: I4BSelectionColumn = grid.gridOptions.selectionColumn.className;
    const buttonColum: I4BButtonColumn = grid.gridOptions.buttonColumn.className;

    const isSelectionVisible = ConditionProcessorUtil.processConditionsWithPermission(buttonColum?.configuration.cell.visibleConditions, userPermissions);

    if (selectionColumn && isSelectionVisible) {
      const selectionColDef = {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: selectionColumn.configuration.id,
        pinned: 'left',
        suppressHeaderMenuButton: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        resizable: false
      };
      return selectionColDef as ColDef;
    } else {
      return null;
    }
  }

  private getButtonColumnDefinition(grid: I4BGrid<I4BGridOptions, I4BGridData>): ColDef | null {
    const userPermissions = this.userPermissions();
    const buttonColumn: I4BButtonColumn = grid.gridOptions.buttonColumn.className;

    const isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(buttonColumn?.configuration.cell.visibleConditions, userPermissions);

    if (buttonColumn && isCallToActionVisible) {
      const buttonColDef: ColDef = {
        field: buttonColumn.configuration.id,
        headerName: buttonColumn.header.displayName ? this.translateService.instant(buttonColumn.header.displayName) : buttonColumn.header.displayName,
        sortable: false,
        suppressHeaderMenuButton: true,
        width: 40,
        maxWidth: 40,
        pinned: 'right',
        headerComponent: HeaderType.CALL_TO_ACTION,
        headerComponentParams: {
          bulkActions: buttonColumn.configuration.cell.bulkActions,
          visibleConditions: buttonColumn.configuration.cell.visibleConditions,
          userPermissions,
          dispatchEvent: (event) => {
            this.dispatchMasterViewEngineEvent.emit(event);
          }
        },
        cellRenderer: I4BCellType.CALL_TO_ACTION,
        cellRendererParams: {
          actions: buttonColumn.configuration.cell.singleActions,
          userPermissions,
          dispatchEvent: (event) => {
            this.dispatchMasterViewEngineEvent.emit(event);
          }
        },
        valueGetter: (params) => JSON.stringify(params.data)
      };
      return buttonColDef;
    } else {
      return null;
    }
  }

  private getColumn(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    if (col.configuration?.children) {
      return this.getColumnDefinitionWithChildren(col);
    } else {
      return this.getColumnDefinition(col);
    }
  }

  private getColumnDefinition(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    const userPermissions = this.userPermissions();
    const headerName = col.options.overrideHeaderName ? col.options.overrideHeaderName : col.header.displayName;
    let columnDefinition: ColDef = {
      field: col.configuration.id,
      headerName: headerName ? this.translateService.instant(headerName) : headerName,
      sortable: col.options.sortable,
      resizable: col.configuration?.resizable !== undefined && col.configuration?.resizable !== null ? col.configuration.resizable : true,
      pinned: col.options.pinned,
      suppressHeaderMenuButton: false,
      cellClassRules: col.configuration.cell?.cellClassRules,
      rowGroup: !!col.configuration?.rowGroup,
      hide: !!col.configuration?.rowGroup ?? !!col.configuration?.hide,
      headerComponent: col.header.type,
      headerComponentParams: {
        enableMenu: false,
        headerIcon: col.header.icon ?? '',
        headerTooltip: col.header.tooltip ?? '',
        showHeaderName: col.header.showHeaderName
      },
      comparator: col.configuration.comparator,
      cellRenderer: col.configuration.cell.type,
      cellRendererParams: {
        autoSize: col.configuration?.autoSize ?? !col.configuration?.width,
        eventConfiguration: {
          type: col.configuration.cell?.event?.type ?? '',
          options: col.configuration.cell?.event?.options ?? ''
        },
        cellOptions: col.configuration.cell?.options ? col.configuration.cell?.options : '',
        userPermissions,
        dispatchEvent: (event) => {
          this.dispatchMasterViewEngineEvent.emit(event);
        }
      }
    };

    /*
    this.groupColors.update((groupColors) => {
      groupColors[columnDefinition.field] = `#${randomColor()}`;
      return groupColors;
    });*/

    if (!col.configuration?.autoSize && !!col.configuration?.width) {
      columnDefinition = {
        ...columnDefinition,
        width: col.configuration.width
      };
    }

    const enableFilter = get(col, ['configuration', 'filterParams', 'enabled'], false);
    if (enableFilter) {
      const filterDefinition = GridFiltersHelpers.getFilter(col);
      columnDefinition = {
        ...columnDefinition,
        headerComponentParams: {
          ...columnDefinition.headerComponentParams,
          enableMenu: true
        },
        ...filterDefinition,
        filterParams: {
          ...filterDefinition.filterParams,
          ...get(col, ['configuration', 'filterParams'])
        },
        suppressFiltersToolPanel: true,
        menuTabs: ['filterMenuTab' /* , 'generalMenuTab', 'columnsMenuTab' */]
      };
    }

    if (col.configuration.cell.valueGetter) {
      if (col.configuration.cell.valueGetter instanceof Function) {
        columnDefinition.valueGetter = col.configuration.cell.valueGetter.bind(this);
      } else {
        columnDefinition.valueGetter = (params: ValueGetterParams) => get(params.data, col.configuration.cell.valueGetter as string);
      }
    }
    if (col.configuration.cell.cellStyle) {
      columnDefinition.cellStyle = col.configuration.cell.cellStyle.bind(this);
    }
    return columnDefinition;
  }

  private getColumnDefinitionWithChildren(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    let children: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...col.configuration.children];

    if (CELL_CONCEPTS_WITH_IS_DISPLAY_OPTION.includes(col.configuration.concept)) {
      children = children.filter((child: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => child?.options?.isDisplayed);
    }

    return {
      ...this.getColumnDefinition(col),
      marryChildren: col.configuration.marryChildren,
      headerGroupComponent: HeaderType.CUSTOM_HEADER_GROUP,
      children: children.map((column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => ({
        ...this.getColumnDefinition(column),
        columnGroupShow: 'open'
      }))
    };
  }

  private setFlexColumnClass() {
    const mainSidenavContentEl = this.document.querySelector('.main-sidenav-content');
    if (mainSidenavContentEl) {
      this.renderer.addClass(mainSidenavContentEl, 'flex-column');
    }
  }
}
